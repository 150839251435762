import { ROUTE_PATH } from "../../../../constants/routePath";
import { useNavigateHook } from "../../../../hooks/useNavigateHook";
import Completion from "../../../Completion";
import SearchTemplate from "../../SearchTemplate";

const SearchPasswordCompletion = () => {
  const { goTo } = useNavigateHook();

  return (
    <SearchTemplate title={"비밀번호 찾기"} tabIndex={2}>
      <Completion
        mainText={"비밀번호 재설정이 완료되었습니다"}
        subText={"재설정된 비밀번호로 로그인 해주세요"}
        firstBtnText={"메인으로 이동"}
        firstBtnOnClick={() => goTo(ROUTE_PATH.HOME)}
        secondBtnText={"로그인 하러 가기"}
        secondBtnOnClick={() => goTo(ROUTE_PATH.LOGINHOME)}
      />
    </SearchTemplate>
  );
};

export default SearchPasswordCompletion;
