import "./styles.scss";
import { baseURL } from "../../../apis";
import KakaoSymbol from "../../svg/icon/KaKaoSymbol";

const KakaoLogin = () => {
  const kakaoUrl = `${baseURL}/accounts/kakao/signup`;

  return (
    <div className="kakao-login__container">
      <a href={kakaoUrl}>
        <KakaoSymbol />
        <span>카카오로 시작하기</span>
      </a>
    </div>
  );
};

export default KakaoLogin;
