import { useSetRecoilState } from "recoil";
import { previousRoute } from "../../recoil/atoms";
import { ROUTE_PATH } from "../../constants/routePath";
import NaverBand from "../svg/icon/NaverBand";
import RightArrow from "../svg/icon/RightArrow";
import Youtube from "../svg/icon/Youtube";
import MainPhoneMan from "../svg/img/MainPhoneMan";
import "./styles.scss";
import { useNavigateHook } from "../../hooks/useNavigateHook";
import { useLocation } from "react-router-dom";

const MainInfo = () => {
  const { goTo } = useNavigateHook();
  const location = useLocation();
  const setPreviousRoute = useSetRecoilState(previousRoute);

  const onClickBtn = (path) => {
    goTo(path);
    setPreviousRoute({ path: location.pathname });
  };
  return (
    <div className="main-info__container">
      <div className="main-info__content">
        <div className="main-info__explain white-box">
          <div className="main-info__title-wrap">
            <h1>
              <span>어</span>느새
              <br />
              <span>부</span>모님을
              <br />
              <span>바</span>라보는 <span>우리</span>
            </h1>
            <p className="txt-subTitle txt-gray">{`돌봄, 계획은 간편하게,\n비용은 부담없게`}</p>
          </div>
          <div className="main-info__img-wrap">
            <MainPhoneMan />
          </div>
        </div>
        <div className="main-info__link-wrap">
          <div className="main-info__banner white-box" onClick={() => alert("준비 중 입니다")}>
            <div className="main-info__link">
              <Youtube width="32" height="32" />
              <div className="main-info__link-text">
                <p className="txt-title">어부바 유튜브</p>
                <p className="txt-sub txt-gray">www.youtube.com/@어부바</p>
              </div>
            </div>
            <div className="icon-wrap">
              <RightArrow width="24" height="24" />
            </div>
          </div>
          <div className="main-info__banner white-box" onClick={() => onClickBtn(ROUTE_PATH.NAVERBAND)}>
            <div className="main-info__link">
              <NaverBand width="32" height="32" />
              <div className="main-info__link-text">
                <p className="txt-title">어부바 밴드</p>
                <p className="txt-sub txt-gray">www.band.us/ko/@어부바</p>
              </div>
            </div>
            <div className="icon-wrap">
              <RightArrow width="24" height="24" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInfo;
