import "./styles.scss";
import { useSetRecoilState } from "recoil";
import { previousRoute } from "../../recoil/atoms";
import { useLocation } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/routePath";
import { useNavigateHook } from "../../hooks/useNavigateHook";
import StrokeButton from "../Button/StrokeButton";
import MainPenMan from "../svg/img/MainPenMan";
import HearingAid from "../svg/icon/HearingAid";
import WheelChair from "../svg/icon/WheelChair";
import Cane from "../svg/icon/Cane";
import Handle from "../svg/icon/Handle";
import BathChair from "../svg/icon/BathChair";
import Matress from "../svg/icon/Matress";
import Footer from "../Footer";

const Main = () => {
  const { goTo } = useNavigateHook();
  const location = useLocation();
  const setPreviousRoute = useSetRecoilState(previousRoute);

  const onClickBtn = (path: string) => {
    goTo(path);
    setPreviousRoute({ path: location.pathname });
  };

  return (
    <main className="main">
      <div className="main__container">
        <div className="main__content">
          <section className="main__rnb">
            <div className="main__rnb__banner col white-box">
              <div className="main__rnb__txt-wrap">
                <h3>1분 만에 예상 요양 등급 확인해 보기</h3>
                <p className="txt-subTitle txt-gray">{`장기요양 등급에 따라 제공받을 수 있는 혜택도\n알아봐요!`}</p>
              </div>
              <StrokeButton
                text={"예상 요양 등급 확인 하기"}
                size={"lg"}
                strokeColor={"primary"}
                onClick={() => goTo(ROUTE_PATH.CAREGRADE)}
              />
            </div>
            <div className="main__rnb__banner-both">
              <div className="main__rnb__banner right-wrap white-box">
                <div className="main__rnb__content-wrap">
                  <h3>어떤 복지 용구가 필요하세요?</h3>
                  <div className="main__rnb__banner__item-wrap">
                    <div className="main__rnb__banner__item">
                      <div className="img-box">
                        <HearingAid width="48" height="48" />
                      </div>
                      <span>보청기</span>
                    </div>
                    <div className="main__rnb__banner__item">
                      <div className="img-box">
                        <WheelChair width="48" height="48" />
                      </div>
                      <span>휠체어</span>
                    </div>
                    <div className="main__rnb__banner__item">
                      <div className="img-box">
                        <Cane width="48" height="48" />
                      </div>
                      <span>지팡이</span>
                    </div>
                    <div className="main__rnb__banner__item">
                      <div className="img-box">
                        <Handle width="48" height="48" />
                      </div>
                      <span>안전손잡이</span>
                    </div>
                    <div className="main__rnb__banner__item">
                      <div className="img-box">
                        <BathChair width="48" height="48" />
                      </div>
                      <span>목욕의자</span>
                    </div>
                    <div className="main__rnb__banner__item">
                      <div className="img-box">
                        <Matress width="48" height="48" />
                      </div>
                      <span>욕창예방 방석</span>
                    </div>
                  </div>
                </div>
                <StrokeButton
                  text={"지원받을 수 있는 복지 용구 알아보기"}
                  size={"lg"}
                  strokeColor={"primary"}
                  onClick={() => onClickBtn(ROUTE_PATH.WELFAREDEVICE)}
                />
              </div>
              <div className="main__rnb__banner left-wrap white-box">
                <div className="main__rnb__content-wrap">
                  <div className="main__rnb__txt-wrap">
                    <h3>{`받을 수 있는 지원금을 알아봐요!`}</h3>
                    <p className="txt-subTitle txt-gray">{`간단한 설문으로 지원금 혜택\n받아가세요!`}</p>
                  </div>
                  <div className="main__rnb__btn-wrap">
                    <StrokeButton
                      text={"지원금 알아보기"}
                      size={"lg"}
                      strokeColor={"primary"}
                      onClick={() => onClickBtn(ROUTE_PATH.SUBSIDY)}
                    />
                  </div>
                </div>
                <div className="main__rnb__img-wrap">
                  <MainPenMan />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Main;
