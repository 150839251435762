import axios from "axios";
import { setInterceptors } from "./interceptors";

export const baseURL = "https://api.nursinghome.ai";

export const createInstance = () => {
  return axios.create({
    baseURL,
  });
};

const createInstanceWithUrl = (url: string) => {
  return axios.create({
    baseURL: `${baseURL}${url}`,
  });
};

const createInstanceWithAuth = (url: string) => {
  const instance = axios.create({
    baseURL: `${baseURL}${url}`,
  });
  setInterceptors(instance);
  return instance;
};

export const instance = createInstance();
export const healthsInstance = createInstanceWithUrl(`/healths`);
export const healthsAccountsInstance = createInstanceWithAuth(`/healths`);
export const accountsInstance = createInstanceWithUrl(`/accounts`);
export const authInstance = createInstanceWithAuth(`/accounts`);
