import "./styles.scss";
import { ROUTE_PATH } from "../../../constants/routePath";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import CommonButton from "../../Button/CommonButton";
import StrokeButton from "../../Button/StrokeButton";
import KakaoLogin from "../KakaoLogin";

const LoginHome = () => {
  const { goTo } = useNavigateHook();

  return (
    <div className="login__container">
      <h2>로그인</h2>
      <div className="login__content">
        <div className="login__kakao">
          <KakaoLogin />
        </div>
        <div className="login__line"></div>
        <div className="login__btn-wrap">
          <StrokeButton
            text={"회원가입"}
            size={"xl"}
            strokeColor={"primary"}
            onClick={() => goTo(ROUTE_PATH.SIGN_UP2)}
          />
          <CommonButton text={"이메일로 시작하기"} size={"xl"} onClick={() => goTo(ROUTE_PATH.EMAILLOGIN)} />
        </div>
      </div>
    </div>
  );
};

export default LoginHome;
