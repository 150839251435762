import "./styles.scss";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getCookieByKey } from "../../../common/function";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";
import MypageSidebar from "../MypageSidebar";
import MypagePasswordHome from "../MypageChangePassword/MypagePasswordHome";
import ModifyUserInfo from "../MypageUserInfo/ModifyUserInfo";
import CheckUserInfo from "../MypageUserInfo/CheckUserInfo";
import CancelUser from "../CancelUserForm";

const MypageHome = () => {
  const token = getCookieByKey("accessToken");
  const { goTo } = useNavigateHook();

  useEffect(() => {
    if (!token) {
      goTo(ROUTE_PATH.HOME);
    }
  }, [token]);

  useEffect(() => {
    // 스크롤 최상단 이동
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mypage__wrap">
      <h2>마이페이지</h2>
      <div className="mypage__container">
        <MypageSidebar />
        <Routes>
          <Route path={"/"} element={<Navigate to={ROUTE_PATH.USERINFO} />} />
          <Route path={"info"} element={<CheckUserInfo />} />
          <Route path={"modify"} element={<ModifyUserInfo />} />
          <Route path={"change"} element={<MypagePasswordHome />} />
          <Route path={"cancel"} element={<CancelUser />} />
        </Routes>
      </div>
    </div>
  );
};

export default MypageHome;
