const KakaoSymbol = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g>
        <path
          d="M10.715 21.887C10.585 21.825 10.4573 21.761 10.332 21.695C7.718 20.315 6 17.983 6 15.337C6 11.101 10.4 7.66699 15.837 7.66699C21.274 7.66699 25.674 11.101 25.674 15.337C25.674 19.573 21.274 23.007 15.837 23.007C15.2222 23.0072 14.6082 22.9624 14 22.873L9.577 25.713L10.715 21.887Z"
          fill="#191600"
        />
      </g>
    </svg>
  );
};

export default KakaoSymbol;
