import NewSignUpTemplate from "../NewSignUpTemplate";
import Completion from "../../Completion";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";

const NewSingUpCompletion = () => {
  const { goTo } = useNavigateHook();

  return (
    <NewSignUpTemplate title={"회원가입 완료"} tabIndex={4}>
      <Completion
        mainText={"회원가입이 완료되었습니다"}
        subText={"이제 어부바 서비스를 이용하실 수 있습니다"}
        firstBtnText={"메인으로 이동"}
        firstBtnOnClick={() => goTo(ROUTE_PATH.HOME)}
        secondBtnText={"로그인 하러 가기"}
        secondBtnOnClick={() => goTo(ROUTE_PATH.LOGINHOME)}
      />
    </NewSignUpTemplate>
  );
};

export default NewSingUpCompletion;
