import "./styles.scss";

interface Props {
  width: string;
  height: string;
}

const Menu: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM6 12C6 11.4477 6.44772 11 7 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H7C6.44772 13 6 12.5523 6 12ZM9 18C9 17.4477 9.44772 17 10 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H10C9.44772 19 9 18.5523 9 18Z"
        fill="#191919"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Menu;
