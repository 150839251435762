import { useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { passwordState } from "../../../../recoil/atoms";
import { usernameState } from "../../../../recoil/selector";
import { postChangeUserPassword } from "../../../../apis/accounts";
import { ROUTE_PATH } from "../../../../constants/routePath";
import useModal from "../../../../hooks/useModal";
import StrokeButton from "../../../Button/StrokeButton";
import CommonButton from "../../../Button/CommonButton";
import CheckModal from "../../../Modal/CheckModal";
import ErrorText from "../../../ErrorText";
import PasswordForm from "../../../PasswordForm";

interface Props {
  setIsCheckPw: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordForm: React.FC<Props> = ({ setIsCheckPw }) => {
  const { state: modalState, openModal, closeModal } = useModal();
  const username = useRecoilValue(usernameState);
  const { password: newPassword, isCorrect } = useRecoilValue(passwordState);
  const resetPassword = useResetRecoilState(passwordState);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // 비밀번호 변경 취소
  const handleChangeCancel = () => {
    resetPassword();
    setIsCheckPw(false);
  };

  // 비밀번호 변경
  const handleChangePassword = () => {
    const requestBody = {
      username: username || "",
      password: newPassword,
    };
    postChangeUserPassword(requestBody)
      .then(() => {
        // console.log("비밀번호 변경 성공:", res);
        setError(false);
        openModal("change");
      })
      .catch((error) => {
        console.error("비밀번호 변경 실패:", error);
        setError(true);
        setErrorMessage(error.data.message);
      });
  };

  return (
    <>
      <div className="mypage__content__input-wrap">
        <PasswordForm isChange={true} />
      </div>
      {error && <ErrorText text={errorMessage} />}
      <div className="mypage__content__btn-wrap">
        <StrokeButton text={"취소"} size={"xl"} strokeColor={"gray"} onClick={handleChangeCancel} />
        <CommonButton text={"변경하기"} size={"xl"} disabled={!isCorrect} onClick={handleChangePassword} />
      </div>
      {/* 모달 팝업 */}
      <CheckModal
        isOpen={modalState.change}
        modalMsg={"비밀번호 변경"}
        returnPath={ROUTE_PATH.USERINFO}
        closeModal={() => closeModal("change")}
      />
    </>
  );
};

export default ChangePasswordForm;
