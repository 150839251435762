import { useEffect } from "react";
import { useNavigateHook } from "../../../hooks/useNavigateHook";
import { ROUTE_PATH } from "../../../constants/routePath";
import { getCookieByKey } from "../../../common/function";
import NewLoginForm from "../../../components/NewLogin/NewLoginForm";

const EmailLogin = () => {
  const isLogin = getCookieByKey("accessToken");
  const { goTo } = useNavigateHook();

  // 로그인 상태에서 접근 시 redirect 처리
  useEffect(() => {
    if (isLogin) {
      goTo(ROUTE_PATH.HOME);
    }
  }, [isLogin, goTo]);

  return (
    <main className="page-main-container">
      <NewLoginForm />
    </main>
  );
};

export default EmailLogin;
