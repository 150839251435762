import "./styles.scss";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  agreementAtom,
  emailState,
  genderState,
  idState,
  nicknameState,
  passwordState,
  phoneAuthState,
  userTypeAtom,
} from "../../../recoil/atoms";
import { postNewSignUp } from "../../../apis/accounts";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_REGEXP } from "../../../constants/signup";
import { isFormValid, setFormatValue } from "../../../common/function";
import { useInput } from "../../../hooks/useInput";
import CommonInput from "../../Input/CommonInput";
import CommonButton from "../../Button/CommonButton";
import NewSignUpTemplate from "../NewSignUpTemplate";
import PhoneAuthForm from "../../PhoneAuthForm";
import PasswordForm from "../../PasswordForm";
import GenderForm from "../../GenderForm";
import NicknameForm from "../../NicknameForm";
import EmailForm from "../../EmailForm";
import IdForm from "../../IdForm";

interface Props {
  setIsCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewSignUpForm: React.FC<Props> = ({ setIsCompleted }) => {
  const userTypeCd = useRecoilValue(userTypeAtom);
  const agreementObject = useRecoilValue(agreementAtom);
  const { id, isCheckId } = useRecoilValue(idState);
  const { nickname, isCheckNickName } = useRecoilValue(nicknameState);
  const { password, isCorrect } = useRecoilValue(passwordState);
  const { email, domain } = useRecoilValue(emailState);
  const { gender } = useRecoilValue(genderState);
  const { phone, isSuccess } = useRecoilValue(phoneAuthState);

  const {
    text: name,
    handleChange: handelChangeName,
    error: nameError,
    handleBlur: handleBlurName,
    errorMsg: nameErrorMessage,
  } = useInput("", SIGNUP_REGEXP.NAME, SIGNUP_ERROR_MESSAGE.NAME);
  const {
    text: birth,
    handleChange: handelChangeBirth,
    error: birthError,
    handleBlur: handleBlurBirth,
    errorMsg: birthErrorMessage,
  } = useInput("", SIGNUP_REGEXP.BIRTH, SIGNUP_ERROR_MESSAGE.BIRTH);

  const [isCheckForm, setIsCheckForm] = useState<boolean>(false);
  // useEffect 의존성 배열에 포함되어 매 렌더링마다 실행 방지하기 위해 useMemo로 메모이제이션 처리
  const initialErrorStates = useMemo(
    () => ({
      username: { hasError: false, errorMsg: "" },
      password: { hasError: false, errorMsg: "" },
      name: { hasError: false, errorMsg: "" },
      nickname: { hasError: false, errorMsg: "" },
      gender: { hasError: false, errorMsg: "" },
      birth: { hasError: false, errorMsg: "" },
      phone: { hasError: false, errorMsg: "" },
    }),
    [],
  );
  const [errorStates, setErrorStates] = useState<{ [key: string]: { hasError: boolean; errorMsg: string } }>(
    initialErrorStates,
  );

  const handleErrors = (errorList: string[]) => {
    const newErrorStates = { ...errorStates };
    Object.keys(errorList).forEach((key: any) => {
      newErrorStates[key] = { hasError: true, errorMsg: errorList[key] };
    });
    setErrorStates(newErrorStates);
  };

  // 회원가입 이벤트
  const hanldeSignup = () => {
    if (!isCheckForm) {
      const formattedBirth = setFormatValue(birth, SIGNUP_REGEXP.BIRTH);
      const formattedEmail = email && domain ? `${email}@${domain}` : "";

      const requestBody = {
        username: id,
        password,
        typeNo: userTypeCd,
        nickname,
        name,
        gender,
        birth: formattedBirth,
        email: formattedEmail,
        phone,
        terms1: agreementObject[1],
        terms2: agreementObject[2],
        terms3: agreementObject[3],
        terms4: agreementObject[4],
      };
      postNewSignUp(requestBody)
        .then(() => {
          // console.log("회원가입 성공:", res);
          setIsCompleted(true);
        })
        .catch((error) => {
          console.error("회원가입 실패", error);
          const data = error.response.data.message;

          if (data) {
            handleErrors(data);
          }
        });
    }
  };

  useEffect(() => {
    // 버튼 활성화 조건
    const form = {
      id,
      name,
      password,
      nickname,
      birth,
      gender,
    };
    const flag = isFormValid(form, SIGNUP_REGEXP) && isCheckId && isCorrect && isCheckNickName && isSuccess;
    setIsCheckForm(!flag);
    if (flag) {
      setErrorStates(initialErrorStates);
    }
  }, [
    id,
    isCheckId,
    name,
    password,
    isCorrect,
    nickname,
    isCheckNickName,
    birth,
    gender,
    isSuccess,
    initialErrorStates,
  ]);

  useEffect(() => {
    if (errorStates.name.hasError) {
      handleBlurName();
    }
    if (errorStates.birth.hasError) {
      handleBlurBirth();
    }
  }, [errorStates.name.hasError, handleBlurName, errorStates.birth.hasError, handleBlurBirth]);

  return (
    <NewSignUpTemplate
      title={"기본정보 입력"}
      subTitle={"어부바 회원정보 입력"}
      subTitleTag={"표시는 반드시 입력해야 하는 항목입니다"}
      tabIndex={3}
    >
      <div className="signup-form__container">
        <div className="signup-form__content">
          <IdForm labelType={"essential"} hasError={errorStates.username.hasError} />
          <PasswordForm isChange={false} labelType={"essential"} hasError={errorStates.password.hasError} />
          <CommonInput
            labelText={"이름"}
            labelType={"essential"}
            type="text"
            name="name"
            value={name}
            maxLength={5}
            placeholder="예: 홍길동"
            error={nameError}
            errorMsg={nameErrorMessage}
            onChange={handelChangeName}
            onBlur={handleBlurName}
          />
          <NicknameForm labelType={"essential"} hasError={errorStates.nickname.hasError} />
          <CommonInput
            labelText={"생년월일"}
            labelType={"essential"}
            type="text"
            name="birth"
            value={birth}
            maxLength={8}
            placeholder="예: 19870101"
            error={birthError}
            errorMsg={birthErrorMessage}
            onChange={handelChangeBirth}
            onBlur={handleBlurBirth}
          />
          <EmailForm />
          <GenderForm labelType={"essential"} hasError={errorStates.gender.hasError} />
          <PhoneAuthForm
            labelType={"essential"}
            hasError={errorStates.phone.hasError}
            errorMsg={errorStates.phone.errorMsg}
          />
        </div>
        <CommonButton text={"다음"} size={"xl"} disabled={isCheckForm} onClick={hanldeSignup} />
      </div>
    </NewSignUpTemplate>
  );
};

export default NewSignUpForm;
