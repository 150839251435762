import { useEffect } from "react";
// import { useNavigateHook } from "../../../hooks/useNavigateHook";
// import { ROUTE_PATH } from "../../../constants/routePath";

const KakaoCallback = () => {
  // const { goTo } = useNavigateHook();

  useEffect(() => {
    const param = new URLSearchParams(window.location.search);
    const code = param.get("code");

    console.log("code", code);
    if (code) {
      localStorage.setItem("code", code);
      // goTo(ROUTE_PATH.LOGINHOME);
    } else {
      // goTo(ROUTE_PATH.LOGINHOME);
    }
  }, []);

  return <div>카카오 인증 처리중 ...</div>;
};

export default KakaoCallback;
