import "./styles.scss";
import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { passwordState } from "../../recoil/atoms";
import { useInput } from "../../hooks/useInput";
import { SIGNUP_ERROR_MESSAGE, SIGNUP_MESSAGE, SIGNUP_REGEXP } from "../../constants/signup";
import PasswordInput from "../Input/PasswordInput";

interface Props {
  isChange: boolean;
  labelType?: string;
  hasError?: boolean;
}

const PasswordForm: React.FC<Props> = ({ isChange, labelType, hasError }) => {
  const setPasswordValue = useSetRecoilState(passwordState);

  const {
    text: password,
    handleChange: handleChangePassword,
    error: passwordError,
    setError: setPasswordError,
    handleBlur: handleBlurPassword,
    errorMsg: passwordErrorMessage,
  } = useInput("", SIGNUP_REGEXP.PASSWORD, SIGNUP_ERROR_MESSAGE.PASSWORD);
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");

  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");

  const [passwordConfirmError, setPasswordConfirmError] = useState<boolean>(false);
  const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState(SIGNUP_ERROR_MESSAGE.PASSWORDCONFIRM);

  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState<boolean>(false);
  const handleShowPassword = () => setIsShowPassword(!isShowPassword);
  const handleShowPasswordConfirm = () => setIsShowPasswordConfirm(!isShowPasswordConfirm);

  const prefixText = isChange ? "새 " : "";

  useEffect(() => {
    if (!password) {
      setPasswordMessage("");
    } else if (!passwordConfirm) {
      setPasswordConfirmMessage("");
    }
    setPasswordValue((prev) => ({ ...prev, password, isCorrect: password === passwordConfirm && !passwordError }));
  }, [password, passwordConfirm, passwordError, setPasswordValue]);

  useEffect(() => {
    if (hasError) {
      handleBlurPassword();
      handleBlurPasswordConfirm();
    }
  }, [hasError, handleBlurPassword]);

  // 비밀번호 확인 blur 함수
  const handleBlurPasswordConfirm = (e?: React.FocusEvent<HTMLInputElement>): void => {
    const value = e ? e.target.value : "";
    if (!value) {
      setPasswordConfirmError(true);
      setPasswordConfirmErrorMessage("확인을 위해 비밀번호를 한 번 더 입력해주세요.");
    } else {
      setPasswordConfirmError(false);
      setPasswordConfirmErrorMessage("");
    }
  };

  // 비밀번호 확인 인풋 입력 시
  const handleChangePasswordConfirm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setPasswordConfirm(value);

      if (value === "" || value !== password) {
        setPasswordConfirmError(true);
        setPasswordConfirmErrorMessage(SIGNUP_ERROR_MESSAGE.PASSWORDCONFIRM);
        setPasswordValue((prev) => ({ ...prev, isCorrect: false }));
      } else {
        setPasswordConfirmError(false);
        setPasswordConfirmMessage(SIGNUP_MESSAGE.PASSWORDCONFIRM);
        setPasswordValue((prev) => ({ ...prev, password, isCorrect: true }));
      }
    },
    [password, setPasswordValue],
  );

  useEffect(() => {
    // 비밀번호 입력 완료 시
    if (password && !passwordError) {
      setPasswordError(false);
      setPasswordMessage(SIGNUP_MESSAGE.PASSWORD);
    }
  }, [passwordError, password, setPasswordError]);

  return (
    <div className="password-form">
      <PasswordInput
        labelText={`${prefixText}비밀번호`}
        labelType={labelType}
        type={isShowPassword ? "text" : "password"}
        name="password"
        value={password}
        minLength={8}
        maxLength={25}
        placeholder={"영문, 숫자, 특수문자 조합 8자 이상"}
        error={passwordError}
        errorMsg={passwordErrorMessage}
        okMsg={passwordMessage}
        onChange={handleChangePassword}
        isShowPassword={isShowPassword}
        handleShowPassword={handleShowPassword}
        onBlur={handleBlurPassword}
      />
      <PasswordInput
        labelText={`${prefixText}비밀번호 확인`}
        labelType={labelType}
        type={isShowPasswordConfirm ? "text" : "password"}
        name="passwordConfirm"
        value={passwordConfirm}
        minLength={8}
        maxLength={25}
        placeholder={"비밀번호 확인"}
        error={passwordConfirmError}
        errorMsg={passwordConfirmErrorMessage}
        okMsg={passwordConfirmMessage}
        onChange={handleChangePasswordConfirm}
        isShowPassword={isShowPasswordConfirm}
        handleShowPassword={handleShowPasswordConfirm}
        onBlur={handleBlurPasswordConfirm}
      />
    </div>
  );
};

export default PasswordForm;
