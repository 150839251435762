import "./styles.scss";
import classNames from "classnames";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/routePath";
import { useNavigateHook } from "../../hooks/useNavigateHook";
import { deleteAllCookies, getCookieByKey } from "../../common/function";
import { previousRoute } from "../../recoil/atoms";
import { postLogout } from "../../apis/accounts";
import CommonButton from "../Button/CommonButton";
import Logo from "../svg/icon/Logo";
import Menu from "../svg/icon/Menu";
import Sidebar from "../Sidebar";

const NewHeader = () => {
  const { goTo } = useNavigateHook();
  const isLogin = getCookieByKey("accessToken");
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = (path: string) => currentPath.includes(path);

  const setPreviousRoute = useSetRecoilState(previousRoute);
  const onClickMenu = () => setPreviousRoute({ path: currentPath });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleSidebar = () => setIsOpen(!isOpen);

  const isCheckMypage = isSelected(ROUTE_PATH.MYPAGE);

  // 로그아웃 이벤트
  const handleLogout = () => {
    postLogout()
      .then(() => {
        // console.log("로그아웃 성공", res);
        deleteAllCookies();
        window.location.reload();
      })
      .catch((error) => {
        console.error("로그아웃 실패", error);
      });
  };

  return (
    <header className="header__container">
      <div className="header__content">
        <div className="header__top-area">
          <div className="header__logo-wrap">
            <Link to={ROUTE_PATH.HOME}>
              <Logo width="64" height="34" />
            </Link>
          </div>
          <div className="header__login-wrap">
            {isLogin ? (
              <>
                {isCheckMypage ? (
                  <div className="header__sidebar-btn">
                    <button onClick={handleSidebar}>
                      <Menu width="24" height="24" />
                    </button>
                  </div>
                ) : (
                  <Link to={ROUTE_PATH.MYPAGE}>내정보</Link>
                )}
              </>
            ) : (
              <CommonButton text={"로그인"} size={"sm"} onClick={() => goTo(ROUTE_PATH.LOGINHOME)} />
            )}
          </div>
        </div>
        <div className="header__bottom-area">
          <div className="header__menu">
            <ul className="header__menu__ul">
              <li>
                <Link to={ROUTE_PATH.CAREGRADE} className={classNames({ selected: isSelected(ROUTE_PATH.CAREGRADE) })}>
                  요양등급
                </Link>
              </li>
              <li>
                <Link to={ROUTE_PATH.QUESTIONBOARD} onClick={onClickMenu}>
                  담소공간
                </Link>
              </li>
              <li>
                <Link to={ROUTE_PATH.FREEBOARD} onClick={onClickMenu}>
                  정부지원
                </Link>
              </li>
              <li>
                <Link to={ROUTE_PATH.COUNSEL} onClick={onClickMenu}>
                  상담하기
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Sidebar isLogin={isLogin} isOpen={isOpen} onClose={handleSidebar} onLogout={handleLogout} />
      </div>
    </header>
  );
};

export default NewHeader;
